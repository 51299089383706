




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import HeroFeatures from '@/components/HeroFeatures.vue';
import HeroIntro from '@/components/HeroIntro.vue';
import ProductFeatures from '@/components/ProductFeatures.vue';
import SupportWW from '@/components/SupportWW.vue';
// import CCLive from '@/components/CCLive.vue';
import CCStreamersPlay from '@/inline-svg/CCStreamersPlay.vue';
import CCViewersChange from '@/inline-svg/CCViewersChange.vue';
import CCBits from '@/inline-svg/CCBits.vue';
import EventsList from '@/components/EventsList.vue';

@Component({
  components: {
    HeroFeatures,
    ProductFeatures,
    HeroIntro,
    SupportWW,
    CCStreamersPlay,
    CCViewersChange,
    CCBits,
    // CCLive,
    EventsList,
  },
})
export default class ServiceTurnipExchange extends Vue {

  private primary = {
    buttonText: `Find an island`,
    type: 'anchor',
    to: 'https://turnip.exchange/islands',
  };
  private secondary = {
    buttonText: `More information`,
    type: 'anchor',
    to: 'https://turnip.exchange/faq',
  };

}
